const theme = {
  breakpoints: ["415px", "769px", "1201px"],
  /*
  e.g.
  breakpoints: [ '40em', '52em', '64em' ]

  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  }
  */
  space: [0, 4, 8, 16, 24, 48, 64, 128, 256],
  colors: {
    blue: "#2c11e7",
    purple: "#dad5fc",
    olive: "#636c33",
    yellow: "#ffb839",
    green: "#339955",
    offBlack: "#2d2f2e",
    offWhite: "#fcfbf7",
  },
  buttons: {
    primary: {
      color: "white",
      bg: "blue",
    },
    outline: {
      color: "blue",
      bg: "transparent",
    },
  },
}

export default theme
