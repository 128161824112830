/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import theme from "./theme"
import { ThemeProvider } from "@emotion/react"
import SimpleReactLightbox from "simple-react-lightbox"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import Header from "./header"
import Footer from "./footer"

const Layout = (props, children) => {
  return (
    <ThemeProvider theme={theme}>
      <SimpleReactLightbox>
        <main
          style={{
            position: "relative",
            minHeight: "100vh",
            paddingBottom: "10vw",
          }}
        >
          <Header
            siteTitle={useSiteMetadata.title}
            pathname={props.location ? props.location.pathname : null}
          />
          {props.children}
          <Footer />
        </main>
      </SimpleReactLightbox>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
