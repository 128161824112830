import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Flex, Box } from "rebass"

const Header = ({ pathname }) => {
  return (
    <header
      style={{
        background: "transparent",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Flex px={[0, 1, 2]}>
        <Box width={1 / 2}>
          <button variant="outline">
            <Link to="/">
              <span
                style={{
                  fontFamily: "Darker Grotesque",
                  fontStyle: "normal",
                  fontWeight: 700,
                }}
              >
                Lim Qian Joo
              </span>
            </Link>
          </button>
        </Box>
        <Box width={1 / 2} textAlign="right">
          <button variant="outline">
            <Link to="/about">About</Link>
          </button>
        </Box>
      </Flex>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  pathname: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Lim Qian Joo's website`,
  pathname: "content",
}

export default Header
