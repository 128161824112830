import React from "react"
import { Flex, Box } from "rebass"

const Footer = ({ siteTitle, handleToggleSidebar }) => (
  <footer
    style={{
      padding: "1rem",
      position: "absolute",
      bottom: 0,
      width: "100%",
      opacity: "0.8",
    }}
  >
    <Flex justifyContent="space-between" flexWrap="wrap">
      <Box width={[1, 2 / 3, 1 / 2]}>
        {new Date().getFullYear()} &copy; Lim Qian Joo.{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          Made with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a> &{" "}
          <a href="https://rebassjs.org">Rebass</a>
        </span>
      </Box>
      <Box width={[1, 1 / 3, 1 / 2]}>
        {/* <ul
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            float: "right",
          }}
        >
          <li style={{ float: "left", marginRight: "1rem" }}>
            <a href="https://linkedin.com/limqianjoo">LinkedIn</a>
          </li>
          <li style={{ float: "left" }}>
            <a href="https://instagram.com/lqjoo">Instagram</a>
          </li>
        </ul> */}
      </Box>
    </Flex>
  </footer>
)

export default Footer
